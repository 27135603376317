import React, { useEffect, useRef, useState } from 'react'
import { Trans, t } from '@lingui/macro'
import styled, { css } from 'styled-components'
import Link from 'next/link'
import ReactGA from 'react-ga'
import { UserAccess } from '@odiupsk/up-api-client'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import {
  Bar,
  BarItem,
  Button,
  ButtonDropdown,
  ButtonLayout,
  ButtonProps,
  ButtonSizes,
  ButtonToggle,
  ButtonTypes,
  Container,
  Grid,
  GridCol,
  Icon,
  List,
  ListItem,
  MainNav,
  MobileNav,
  Section,
} from '../../ui-kit'
import {
  DEV_HOSTNAMES,
  PREPROD_HOSTNAMES,
  PRODUCT,
  PRODUCTS,
  SCOPES,
  STAGING_HOSTNAMES,
  getProductPageHref,
} from '../../../config/constants'
import { LAST_BUILD_DATE, UP_API_BASE_URL } from '../../../config'

import { Auth } from '../auth/Auth'
import { Notifications } from './Notifications'
import ProductChangeDropdown from './header/ProductChangeDropdown'
/* eslint-disable no-alert */
import { _ } from '../../utils/intl'
import { appStore } from '../../AppStore'
import { clientStore } from '../../../client/ClientStore'
import { employeeStore } from '../../../end-user/EmployeeStore'
import { removeSavedFiltersFromStorage } from '../../../../pages/client/addresses-and-departments'
import { useAuthLoop } from '../../../contexts/AuthLoopContext'

type navItemsEntityType = {
  [x: string]: {
    dropdownStartPos: {
      s?: number
      m?: number
      l?: number
      xl?: number
    }
    color: string
    items: {
      itemTitle: string
      href: string
      // by default it takes a router path into consideration, but you can override to true here
      isActive?: boolean
      logoTop?: React.ReactElement | undefined
    }[]
  }
}
interface navItemsInterface {
  [key: number]: navItemsEntityType
}

interface UserDropdownListItemOwnProps {
  className?: string
  title?: string
  subtitle?: string
  icon?: string
  buttonType?: ButtonTypes
  buttonClasses?: string
  buttonSize?: ButtonSizes
}

type UserDropdownListItemProps = ButtonProps & UserDropdownListItemOwnProps

const UserDropdownListItem: React.SFC<UserDropdownListItemProps> = ({
  className,
  title,
  subtitle,
  icon,
  buttonType = 'link-secondary',
  buttonClasses = 'text-left',
  buttonSize,
  ...other
}: UserDropdownListItemProps) => (
  <ListItem className={cx('no-pad', className)}>
    <ButtonLayout className="mb-none">
      <Button type={buttonType} size={buttonSize} className={buttonClasses} {...other}>
        <div className="align-items-middle" style={{ display: 'flex' }}>
          {icon && (
            <div>
              <Icon name={icon} size="medium" className="icon--left" />
            </div>
          )}
          <div>
            {title}
            {subtitle && <span className="user-dropdown-item-company">{subtitle}</span>}
          </div>
        </div>
      </Button>
    </ButtonLayout>
  </ListItem>
)

export const UserSettingsDropdown = observer(() => {
  const { currentRole, currentUserAccesses } = appStore
  const { stopRefreshLoop } = useAuthLoop()

  const userDropdownLogout: UserDropdownListItemProps = {
    title: _(t`Odhlásiť sa`),
    buttonType: 'link',
    buttonClasses: 'text-center border-top border-light-grey',
    onClick: () => {
      Auth.logout()
      stopRefreshLoop()
    },
  }

  const userDropdownProfile: UserDropdownListItemProps = {
    title: _(t`Nastavenia profilu`),
    buttonType: 'link',
    buttonClasses: 'text-center border-top border-light-grey',
    onClick: () => {
      location.pathname = '/profile'
    },
    icon: 'settings',
  }

  let currentUserAccess

  if (currentRole === SCOPES.Holder && employeeStore.employee) {
    currentUserAccess = appStore.getUserAccessFromId(employeeStore.employee?.id)
  } else if (currentRole === SCOPES.Client) {
    currentUserAccess = clientStore.client
  }

  if (!currentUserAccess || !currentUserAccesses) return null

  const userDropdownName: UserDropdownListItemProps = {
    title: appStore.currentUser?.name,
    icon: 'user',
    buttonSize: 'small',
  }

  return (
    <div className="user-top-dropdown">
      <ButtonDropdown
        key="used-dropdown"
        space="condensed"
        type="link-secondary"
        data-testid="btn-context-switcher"
        dropdownArrow
        popperOptions={{
          placement: 'bottom-end',
          modifiers: {
            arrow: {
              element: '.dropdown__arrow',
            },
          },
        }}
        content={
          <React.Fragment>
            <div
              css={css`
                display: flex;
                align-items: center;
              `}>
              <strong className="curr-user-login-email">{appStore.currentUser?.email}</strong>
              <Icon data-toggle-icon-target name="chevron-down" className="icon--right" />
            </div>
          </React.Fragment>
        }>
        <List clean className="mb-none">
          <Section size="small">
            <UserDropdownListItem
              {...userDropdownName}
              css={css`
                pointer-events: none;
              `}
            />
            <UserDropdownListItem {...userDropdownProfile} />
            <UserDropdownListItem {...userDropdownLogout} />
          </Section>
        </List>
      </ButtonDropdown>
    </div>
  )
})

export const UserDropdown = observer(() => {
  const { currentRole, currentUserAccesses } = appStore

  let currentUserAccess
  let name
  let iconName
  if (currentRole === SCOPES.Holder && employeeStore.employee) {
    iconName = 'user'
    currentUserAccess = appStore.getUserAccessFromId(employeeStore.employee?.id)
    if (currentUserAccess) {
      name = `${currentUserAccess.name}`
    }
  } else if (currentRole === SCOPES.Client) {
    iconName = 'company'
    currentUserAccess = clientStore.client
    if (currentUserAccess) {
      name = `${currentUserAccess.name}`
    }
  }

  if (!currentUserAccess || !currentUserAccesses) return null

  const hideDropdown = !(currentUserAccesses && currentUserAccesses.length > 1)

  return (
    <ButtonDropdown
      key="used-dropdown"
      space="condensed"
      type="link-secondary"
      data-testid="btn-context-switcher"
      dropdownArrow
      iconToggle={hideDropdown ? iconName : 'chevron-up'}
      popperOptions={{
        placement: 'bottom-end',
        modifiers: {
          arrow: {
            element: '.dropdown__arrow',
          },
        },
      }}
      css={
        hideDropdown
          ? css`
              pointer-events: none;
            `
          : undefined
      }
      content={
        <React.Fragment>
          <div
            css={css`
              display: flex;
              align-items: center;
            `}>
            <Icon name={iconName} size="medium" />
            <span className="show-xl">&nbsp;&nbsp;</span>
            <strong
              className="show-xl"
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: 460,
              }}>
              {name}
            </strong>
            {!hideDropdown && (
              <Icon data-toggle-icon-target name="chevron-down" className="icon--right show-l" />
            )}
          </div>
        </React.Fragment>
      }>
      {!hideDropdown && (
        <List clean className="mb-none">
          <UserAccessesList
            accesses={currentUserAccesses}
            currentUserAccessId={currentUserAccess.id}
          />
        </List>
      )}
    </ButtonDropdown>
  )
})

UserDropdown.displayName = 'UserDropdown'

const UnAuthorizedHeaderLogin = observer(function UnAuthorizedHeaderLogin() {
  return appStore.isLoggedIn === false ? (
    <Link href="/auth">
      <a className="btn btn--link-secondary">
        <Icon size="medium" name="login" />
        <Trans>Prihlásiť sa</Trans>
      </a>
    </Link>
  ) : null
})

const UserAccessesList = (props: { accesses: UserAccess[]; currentUserAccessId: string }) => {
  const { accesses, currentUserAccessId } = props
  const sortByRoleAndName = (a: UserAccess, b: UserAccess) => {
    if (!a?.scope || !b?.scope) {
      return 0
    }

    // @ts-ignore-next-line
    return a.scope - b.scope || a.name.localeCompare(b.name)
  }

  const userAccessesItems = accesses
    .filter(access => currentUserAccessId !== access.scope_object_id)
    .sort(sortByRoleAndName)
    .map(
      (access): UserDropdownListItemProps => ({
        title: access.name,
        subtitle: access.scope === SCOPES.Holder ? access.subject_name : undefined,
        icon: access.scope === SCOPES.Holder ? 'user' : 'company',
        buttonSize: 'small',
        onClick: async () => {
          removeSavedFiltersFromStorage()
          ReactGA.event({
            category: 'Auth',
            action: 'Context switch',
            value: access.scope,
          })
          if (access.scope === SCOPES.Holder) {
            employeeStore.saveEmployeeIdToLocalStorage(access.scope_object_id)
            location.assign('/end-user')
          } else if (access.scope === SCOPES.Client) {
            clientStore.saveClientIdToLocalStorage(access.scope_object_id)
            location.assign('/client')
          }
        },
      })
    )

  if (userAccessesItems.length === 0) return null

  return (
    <Section size="small">
      {userAccessesItems.map(item => (
        <UserDropdownListItem key={item.title} {...item} />
      ))}
    </Section>
  )
}

const SettingsIcon = observer(() => {
  const [href, setHref] = useState('')

  useEffect(() => {
    if (appStore.currentRole === SCOPES.Client) setHref('/client/settings/company')
    if (appStore.currentRole === SCOPES.Holder) setHref('/end-user/settings/notifications')
  }, [])

  return (
    <Link href={href}>
      <a className="btn btn--equal btn--condensed btn--link-secondary">
        <Icon size="medium" name="settings" />
      </a>
    </Link>
  )
})

const PreProdNoticeBanner = () => {
  const [hostname, setHostname] = useState('')

  useEffect(() => {
    setHostname(location.hostname)
  }, [])

  if (PREPROD_HOSTNAMES.includes(hostname)) {
    return (
      <div style={{ backgroundColor: 'red', width: '100%', textAlign: 'center', padding: '6px 0' }}>
        <span
          className="text-small text-color-white text-center"
          style={{ textTransform: 'uppercase' }}>
          <Trans>Pozor pre-prod prostredie,</Trans> <Trans>verzia api:</Trans>{' '}
          <strong>{UP_API_BASE_URL.toString().replace('/', ' ')}, </strong>
          <Trans>Posledný update: </Trans> {LAST_BUILD_DATE}
        </span>
      </div>
    )
  }

  if (DEV_HOSTNAMES.includes(hostname)) {
    return (
      <div
        style={{ backgroundColor: 'green', width: '100%', textAlign: 'center', padding: '6px 0' }}>
        <span
          className="text-small text-color-white text-center"
          style={{ textTransform: 'uppercase' }}>
          <Trans>Pozor prostredie develop,</Trans> <Trans>verzia api:</Trans>{' '}
          <strong>{UP_API_BASE_URL.toString().replace('/', ' ')}, </strong>
          <Trans>Posledný update: </Trans> {LAST_BUILD_DATE}
        </span>
      </div>
    )
  }

  if (STAGING_HOSTNAMES.includes(hostname)) {
    return (
      <div
        style={{ backgroundColor: 'orange', width: '100%', textAlign: 'center', padding: '6px 0' }}>
        <span
          className="text-small text-color-white text-center"
          style={{ textTransform: 'uppercase' }}>
          <Trans>Pozor prostredie staging,</Trans> <Trans>verzia api:</Trans>{' '}
          <strong>{UP_API_BASE_URL.toString().replace('/', ' ')}, </strong>,
          <Trans>Posledný update: </Trans> {LAST_BUILD_DATE}
        </span>
      </div>
    )
  }

  if (hostname.includes('moje-feature') || hostname.includes('localhost')) {
    return (
      <div
        style={{ backgroundColor: 'orange', width: '100%', textAlign: 'center', padding: '6px 0' }}>
        <span
          className="text-small text-color-white text-center"
          style={{ textTransform: 'uppercase' }}>
          <Trans>Pozor feature prostredie,</Trans> <Trans>verzia api:</Trans>{' '}
          <strong>{UP_API_BASE_URL.toString().replace('/', ' ')}, </strong>
          <Trans>Posledný update: </Trans> <strong>{LAST_BUILD_DATE}</strong>
        </span>
      </div>
    )
  }

  return null
}
export interface ClubUpLogoProps {
  small?: boolean
}
const ClubUpLogo = ({ small }: ClubUpLogoProps) => {
  return (
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transform: small ? undefined : 'translateY(-6px)',
      }}>
      <span style={{ fontSize: small ? '8px' : '16px', fontWeight: 'bold' }}>Klub</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={small ? '10' : '20'}
        height={small ? '10' : '20'}
        viewBox="0 0 50 50"
        style={{ marginTop: '10px' }}>
        <g fill="none">
          <polygon fill="#EB8C2D" points="0 50 50 50 50 0 0 0" />
          <path
            fill="#FFFFFE"
            d="M19.9425523 25.4431413C19.9425523 28.0229799 19.3892326 30.004728 16.5615758 30.004728 13.3955109 30.004728 13.0574477 27.6766241 13.0574477 25.7891441L13.0574477 11 9 11.9151418 9 26.3872344C9 31.892208 12.7500862 34 16.3770209 34 21.1116298 34 24 30.8859757 24 26.9842656L24 11 19.9425523 11.9151418 19.9425523 25.4431413zM34.4152848 30.221323C31.6856525 30.221323 29.6935833 27.9954258 29.6935833 25.3926812 29.6935833 22.7906404 31.6856525 20.5640394 34.4152848 20.5640394 37.1456381 20.5640394 39.1377073 22.7906404 39.1377073 25.3926812 39.1377073 27.9954258 37.1456381 30.221323 34.4152848 30.221323M34.6766402 16.9437016C32.9682769 16.9437016 31.4116799 17.4475721 30.0908435 18.3444757L30.0908435 16 26 16.9658691 26 39 30.0796683 38.0872625 30.0796683 32.447924C31.3961788 33.3367347 32.9769286 33.8553835 34.6766402 33.8553835 39.2736121 33.8553835 43 30.0693174 43 25.3997185 43 20.7294159 39.2736121 16.9437016 34.6766402 16.9437016"
          />
        </g>
      </svg>
    </span>
  )
}

const ProductMenuWrapper = styled.div`
  height: 68px;
  display: flex;
  margin-top: 5px;
  & li > a {
    color: white;
    padding: 8px;
    border-radius: 3px;
  }
  & li.is-active > a {
    color: #48544d;
    background: white;
  }
`

export const Header: React.FC<{ userRole: SCOPES }> = observer(function Header({ userRole }) {
  const mobileNavToggleButtonRef = useRef(null)
  const { stopRefreshLoop } = useAuthLoop()

  const navItems: navItemsInterface = {
    [SCOPES.Client]: {
      general: {
        color: 'white',
        dropdownStartPos: {
          l: 5,
          xl: 5,
        },
        items: [
          {
            itemTitle: _(t`Adresy a oddelenia`),
            href: '/client/addresses-and-departments',
          },
          {
            itemTitle: _(t`Archív dokumentov`),
            href: '/client/documents-archive',
          },
          {
            itemTitle: _(t`Špeciálna ponuka`),
            href: '/client/special-offers',
            logoTop: <ClubUpLogo small />,
          },
          {
            itemTitle: _(t`Plná peňaženka`),
            href: '/client/full-wallet-offers',
            logoTop: <ClubUpLogo small />,
          },
          {
            itemTitle: _(t`Rady a inšpirácie`),
            href: '/client/articles',
          },
          {
            itemTitle: _(t`Kontakt`),
            href: '/client/contact',
          },
        ],
      },
      [PRODUCT.SHELL]: {
        color: 'white',
        dropdownStartPos: {
          l: 5,
          xl: 5,
        },
        items: [
          {
            itemTitle: _(t`Prehľad`),
            href: '/client',
          },
          {
            itemTitle: _(t`Transakcie`),
            href: getProductPageHref(PRODUCT.SHELL, 'transactions'),
          },
          {
            itemTitle: _(t`Karty`),
            href: getProductPageHref(PRODUCT.SHELL, 'cards'),
          },
          {
            itemTitle: _(t`Akceptačné miesta`),
            href: getProductPageHref(PRODUCT.SHELL, 'acceptation-places'),
          },
        ],
      },
      [PRODUCT.DEJEUNER]: {
        color: 'white',
        dropdownStartPos: {
          l: 5,
          xl: 5,
        },
        items: [
          {
            itemTitle: _(t`Prehľad`),
            href: '/client',
          },
          {
            itemTitle: _(t`Objednať produkty`),
            href: getProductPageHref(PRODUCT.DEJEUNER, 'product-selection'),
          },
          {
            itemTitle: _(t`Zamestnanci a karty`),
            href: getProductPageHref(PRODUCT.DEJEUNER, 'cards'),
          },
          {
            itemTitle: _(t`Objednávky`),
            href: getProductPageHref(PRODUCT.DEJEUNER, 'orders-history'),
          },
          {
            itemTitle: _(t`Akceptačné miesta`),
            href: getProductPageHref(PRODUCT.DEJEUNER, 'acceptation-places'),
          },
        ],
      },
    },
    [SCOPES.Holder]: {
      general: {
        color: 'white',
        dropdownStartPos: {
          l: 5,
          xl: 6,
        },
        items: [
          {
            itemTitle: _(t`Domov`),
            href: '/end-user',
          },
          {
            itemTitle: _(t`Prehľad a hry`),
            href: '/end-user/statistics',
          },
          {
            itemTitle: _(t`Špeciálna ponuka`),
            href: '/end-user/special-offers',
            logoTop: <ClubUpLogo />,
          },
          {
            itemTitle: _(t`Plná peňaženka`),
            href: '/end-user/full-wallet-offers',
            logoTop: <ClubUpLogo />,
          },
          {
            itemTitle: _(t`Rady a inšpirácie`),
            href: '/end-user/articles',
          },
          {
            itemTitle: _(t`Kontakt`),
            href: '/end-user/contact',
          },
        ],
      },
      [PRODUCT.DEJEUNER]: {
        color: 'white',
        dropdownStartPos: {
          l: 5,
          xl: 6,
        },
        items: [
          {
            itemTitle: _(t`Akceptačné miesta`),
            href: '/end-user/acceptation-places',
          },
          {
            itemTitle: _(t`Štatistiky`),
            href: '/end-user/statistics',
          },
        ],
      },
    },
    [SCOPES.Affiliate]: {
      general: {
        color: 'white',
        dropdownStartPos: {
          l: 5,
          xl: 6,
        },

        items: [
          // {
          //   itemTitle: _(t`Domov`),
          //   href: '#',
          // },
          // {
          //   itemTitle: _(t`Up Merchant`),
          //   href: '#',
          // },
          // {
          //   itemTitle: _(t`Personál a prevádzky`),
          //   href: '#',
          // },
          // {
          //   itemTitle: _(t`Produkty a služby`),
          //   href: '#',
          // },
          // {
          //   itemTitle: _(t`Archív dokumentov`),
          //   href: '#',
          // },
          // {
          //   itemTitle: _(t`Up Partner`),
          //   href: '#',
          // },
          // {
          //   itemTitle: _(t`Rady a inšpirácie`),
          //   href: '#',
          // },
          // {
          //   itemTitle: _(t`Kontakt`),
          //   href: '#',
          // },
        ],
      },
    },
    [SCOPES.Public]: {
      general: {
        color: 'white',
        dropdownStartPos: {
          l: 5,
          xl: 6,
        },
        items: [
          {
            itemTitle: _(t`Objednať produkty`),
            href: '/product-selection',
          },
          {
            itemTitle: _(t`Rady a inšpirácie`),
            href: '/articles',
          },
          {
            itemTitle: _(t`Kontakt`),
            href: '/contact',
          },
        ],
      },
    },

    [SCOPES.User]: {
      general: {
        color: 'white',
        dropdownStartPos: {
          l: 5,
          xl: 6,
        },
        items: [],
      },
    },
  }

  // debugger
  // @ts-ignore
  // if (!userRole || SCOPES.Public === userRole) {
  //   return <UnAuthorizedHeader />
  // }

  const mobileNavToggleButton = (
    <ButtonToggle
      key="mobile-menu-toggle"
      aria-expanded="false"
      aria-controls="mobile-nav"
      type="link-secondary"
      equal
      className="hide-l mb-none"
      data-toggle={JSON.stringify([
        {
          target: 'self',
          attribute: 'class',
          value: 'is-active',
        },
        {
          target: 'self',
          attribute: 'aria-expanded',
        },
        {
          target: 'self',
          attribute: 'icon',
          value: '#close',
        },
        {
          target: 'self',
          attribute: 'text',
          value: 'Close menu',
        },
        {
          target: `#mobile-nav`,
          attribute: 'class',
          value: 'is-active',
        },
        {
          target: 'body',
          attribute: 'lock-body',
        },
      ])}>
      <span className="sr-only" data-toggle-text-target ref={mobileNavToggleButtonRef}>
        <Trans>Open menu</Trans>
      </span>
      <Icon name="menu" size="medium" />
    </ButtonToggle>
  )

  let actions: JSX.Element[]

  if (SCOPES.Public === userRole) actions = [<UnAuthorizedHeaderLogin />, mobileNavToggleButton]
  else if (SCOPES.User === userRole || appStore.is404)
    actions = [
      <a
        className="btn btn--link-secondary"
        onClick={() => {
          Auth.logout()
          stopRefreshLoop()
        }}>
        <Trans>Odhlásiť sa</Trans>
      </a>,
    ]
  else actions = [<UserDropdown />, <SettingsIcon />, mobileNavToggleButton]

  return (
    <React.Fragment>
      <a href="#main" className="skip-link">
        <Trans>Preskočiť na obsah</Trans>
      </a>
      <aside>
        <PreProdNoticeBanner />

        <Section size="small" color="black">
          <Container>
            <Grid>
              <GridCol size="auto">
                <a
                  className="link text-color-white text-small"
                  href="https://up-dejeuner.sk/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <Icon name="chevron-left" className="text-vam" />
                  <Trans>
                    Späť na webstránku <span className="show-l">Up Slovensko</span>
                  </Trans>
                </a>
              </GridCol>
              <GridCol size="auto" className="text-right text-l-center">
                <span className="text-small text-color-white text-bold text-center">
                  <Trans>Zákaznícka zóna</Trans>
                </span>
              </GridCol>

              <GridCol
                size={userRole !== SCOPES.Public && !appStore.is404 ? 'fixed' : 'auto'}
                className="text-right top-user-nav-grid">
                {userRole !== SCOPES.Public && !appStore.is404 && (
                  <nav className="top-user-nav">
                    <Bar
                      tag="ul"
                      className="list--unstyled mb-none align-items-stretch justify-content-between justify-content-l-right">
                      <BarItem tag="li">
                        <UserSettingsDropdown />
                      </BarItem>
                      <BarItem tag="li" className="notifications-bell">
                        <Notifications />
                      </BarItem>
                    </Bar>
                  </nav>
                )}
              </GridCol>
            </Grid>
          </Container>
        </Section>
      </aside>
      <Container>
        <MainNav
          id="main-nav"
          hideHelp
          logo={
            <a href="/">
              <img src="/static/img/logo/logo-small.svg" alt="Up" width="64" height="63" />
            </a>
          }
          itemClassName="show-l"
          items={appStore.is404 ? [] : navItems[userRole].general?.items}
          dropdownStartPos={navItems[userRole].general?.dropdownStartPos}
          actions={actions}
        />
        <MobileNav
          id="mobile-nav"
          items={navItems[userRole].general?.items}
          isActive={false}
          toggleButtonRef={mobileNavToggleButtonRef}
        />
      </Container>

      {userRole === SCOPES.Client && (
        <ProductMenuWrapper
          style={{
            background:
              clientStore.activeProduct === PRODUCT.SHELL
                ? PRODUCTS.SHELL.backgroundColor
                : PRODUCTS.DEJEUNER.backgroundColor,
          }}>
          <Container>
            <MainNav
              actionsFirst
              style={{ marginTop: 12 }}
              actions={[<ProductChangeDropdown />]}
              id="product-nav"
              hideHelp
              itemClassName="show-l"
              items={
                clientStore.activeProduct
                  ? navItems[userRole][clientStore.activeProduct]?.items || []
                  : []
              }
            />
          </Container>
        </ProductMenuWrapper>
      )}
    </React.Fragment>
  )
})
