import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'core-js/proposals/url'
import '../src/common/utils/polyfills'

import App from 'next/app'
import React from 'react'
import bugsnagReact from '@bugsnag/plugin-react'
import { Alerts } from '../src/common/ui-kit/alert/Alert'
import { AuthLoopProvider } from '../src/contexts/AuthLoopContext'
import Base from '../src/common/modules/layout/Base'
import { MAINTENANCE_MODE } from '../src/config/constants'
import { MaintenancePageContent } from '../src/maintenance/MaintenancePageContent'
import { bugsnagClient } from '../src/config'

bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = bugsnagClient.getPlugin('react')

class UpApp extends App {
  state = {
    lang: 'en',
    showLoginModal: false,
  }

  static getInitialProps = async ({ Component, ctx }: any) => {
    // console.log(router)
    let pageProps = {}

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }

    return { pageProps }
  }

  // componentDidMount() {
  // if (!isProductionHostname && !isUatHostname) {
  // eslint-disable-next-line
  // new MobxConsoleLogger(mobx, { useGroups: true })
  // }
  // }

  render() {
    const { Component, pageProps } = this.props

    return (
      <ErrorBoundary>
        <AuthLoopProvider>
          <Base>
            {!MAINTENANCE_MODE ? (
              <>
                <Component {...pageProps} />
                <Alerts />
              </>
            ) : (
              <MaintenancePageContent />
            )}
          </Base>
        </AuthLoopProvider>
      </ErrorBoundary>
    )
  }
}

export default UpApp
