import { computed, observable, runInAction } from 'mobx'
import { Notice } from '@odiupsk/up-api-client'
import { noticesApi, usersApi } from '../../../api'

/* eslint-disable class-methods-use-this */
import { PaginationStore } from './PaginationStore'
import { appStore } from '../../../AppStore'

export class NotificationStore {
  @observable.ref notifications: Notice[] = []

  @observable.ref loading: boolean = false

  @observable.ref numberOfUnread = 0

  pagination = new PaginationStore({ perPage: 30 })

  async fetchNotices(params: this['params']) {
    if (!appStore.currentUser) return

    runInAction(() => {
      this.loading = true
    })

    const response = await usersApi.getNotices(
      appStore.currentUser.id,
      params.page,
      params.per_page
    )

    const numberOfUnreadResponse = await noticesApi.getNoticesAcknowledgement()
    const data = response.data

    runInAction(() => {
      this.notifications = [...this.notifications, ...((data.data as any) as Notice[])]
      this.pagination.setFromPagination(data.pagination)
      this.loading = false
      this.numberOfUnread = numberOfUnreadResponse.data
    })
  }

  async refreshNotices(withData = true) {
    if (!appStore.currentUser) return

    if (withData) {
      const response = await usersApi.getNotices(
        appStore.currentUser.id,
        1,
        this.pagination.perPage * this.pagination.page
      )

      const data = response.data

      runInAction(() => {
        this.notifications = (data.data as any) as Notice[]
      })
    }

    const numberOfUnreadResponse = await noticesApi.getNoticesAcknowledgement()

    runInAction(() => {
      this.numberOfUnread = numberOfUnreadResponse.data
    })
  }

  async acknowledgeAll() {
    if (!appStore.currentUser) return

    await noticesApi.postNoticesAcknowledgeAll()
    await this.refreshNotices(true)
  }

  @computed get params() {
    return { ...this.pagination.params }
  }
}
