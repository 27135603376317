import { UserMessage } from '@odiupsk/up-api-client'
import { computed, observable, runInAction } from 'mobx'

import { PaginationStore } from './PaginationStore'
import { appStore } from '../../../AppStore'
import { messagesApi, usersApi } from '../../../api'

export class MessageStore {
  @observable.ref messages: UserMessage[] = []

  @observable.ref loading: boolean = false

  @observable.ref numberOfUnread = 0

  pagination = new PaginationStore({ perPage: 30 })

  async fetchMessages(params: this['params']) {
    if (!appStore.currentUser) return

    runInAction(() => {
      this.loading = true
    })

    const response = await usersApi.getMessages(
      appStore.currentUser.id,
      params.page,
      params.per_page
    )

    const numberOfUnreadResponse = await messagesApi.getMessagesAcknowledgement()
    const data = response.data

    runInAction(() => {
      this.messages = [...this.messages, ...((data.data as any) as UserMessage[])]
      this.pagination.setFromPagination(data.pagination)
      this.loading = false
      this.numberOfUnread = numberOfUnreadResponse.data
    })
  }

  async refreshMessages(withData = true) {
    if (!appStore.currentUser) return

    if (withData) {
      const response = await usersApi.getMessages(
        appStore.currentUser.id,
        1,
        this.pagination.perPage * this.pagination.page
      )

      const data = response.data

      runInAction(() => {
        this.messages = (data.data as any) as UserMessage[]
      })
    }

    const numberOfUnreadResponse = await messagesApi.getMessagesAcknowledgement()

    runInAction(() => {
      this.numberOfUnread = numberOfUnreadResponse.data
    })
  }

  @computed get params() {
    return { ...this.pagination.params }
  }
}
