import React, { createContext, useContext, useEffect, useState } from 'react'

import { Auth } from '../common/modules/auth/Auth'

//
// CONTEXT
//
const AuthLoopContext = createContext<any>(null)

//
// HOOK
//
export const useAuthLoop = () => {
  return useContext(AuthLoopContext)
}

//
// PROVIDER
//
export const AuthLoopProvider: React.FC = ({ children }) => {
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [refreshInterval, setRefreshInterval] = useState<NodeJS.Timeout | null>(null)

  const startRefreshLoop = () => {
    if (refreshInterval) clearInterval(refreshInterval)

    const refreshRate = Auth.getAccessTokenRefreshRate() || 0

    console.log(`START REFRESH ACCESS_TOKEN LOOP (every ${refreshRate} sec)`)

    if (refreshRate > 0) {
      const interval = setInterval(() => {
        if (!isRefreshing) {
          console.log(`REFRESHING ACCESS_TOKEN (every ${refreshRate} sec)`)
          setIsRefreshing(true)
          Auth.fetchNewAccessToken().finally(() => setIsRefreshing(false))
        }
      }, refreshRate * 1000)
      setRefreshInterval(interval)
    }
  }

  const stopRefreshLoop = () => {
    console.log('STOP REFRESH ACCESS_TOKEN LOOP')
    if (refreshInterval) {
      clearInterval(refreshInterval)
      setRefreshInterval(null)
    }
  }

  useEffect(() => {
    if (Auth.getAccessToken()) {
      // Trigger refresh loop on page load if user is already logged in
      startRefreshLoop()
    }

    return () => {
      stopRefreshLoop()
    }
  }, [])

  return (
    <AuthLoopContext.Provider value={{ startRefreshLoop, stopRefreshLoop }}>
      {children}
    </AuthLoopContext.Provider>
  )
}
